import Grid from '@mui/material/Grid'
import { useState } from 'react'
import HeroBanner from '@bluheadless/ui/src/organisms/hero/hero-banner'
import {
	Container,
	Title,
	Description,
	CtaContainer,
	Button,
} from '@bluheadless/ui/src/organisms/hero/hero-banner.styled'
import {
	default as ImageArtDirection,
	ImageBackgroundArtDirection,
	VideoArtDirection,
} from '@bluheadless/ui/src/particles/art-direction'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { DialogStyled } from './dialog-video-banner.styled'
import { Parallax } from 'react-scroll-parallax'

const DialogVideoBanner = ({
	aboveTheFold,
	title,
	titleProps,
	description,
	descriptionProps,
	cta,
	ctaProps,
	additionalContent,
	video,
	videoConfig,
	image,
	imageConfig,
	...props
}) => {
	const [dialogVideo, setDialogVideo] = useState(false)

	const BackgroundComponent = aboveTheFold ? ImageBackgroundArtDirection : ImageArtDirection

	const { siteName } = useConfig()

	return (
		<>
			<HeroBanner
				aboveTheFold={aboveTheFold}
				overlayComponent={
					<Container style={{ textAlign: props.horizontalAlign }}>
						{title && (
							<Parallax easing="easeOut" speed={-3}>
								<Title variant="headlinebig" {...titleProps}>
									{title}
								</Title>
							</Parallax>
						)}
						{description && (
							<Description variant="subheadline1" {...descriptionProps}>
								{description}
							</Description>
						)}
						{cta && cta?.length > 0 && (
							<Parallax easing="easeOut" speed={-3}>
								<CtaContainer
									display="inline-flex"
									justifyContent={props.horizontalAlign}
									hasSiblings={!!description || !!title}
									{...ctaProps}
								>
									{cta.map(({ label, ...rest }, key) => (
										<Grid item key={key}>
											<Button {...rest} onClick={() => setDialogVideo(true)} href={null}>
												{label}
											</Button>
										</Grid>
									))}
								</CtaContainer>
							</Parallax>
						)}
						{additionalContent}
					</Container>
				}
				backgroundComponent={
					<BackgroundComponent
						aboveTheFold={aboveTheFold}
						alt={title ? title + ' | ' + siteName : siteName}
						sources={{
							xs: image?.mobile,
							md: image?.desktop,
						}}
						style={{
							width: aboveTheFold ? '100%' : undefined,
							height: aboveTheFold ? '100%' : undefined,
						}}
						{...(image ? imageConfig : {})}
					/>
				}
				{...props}
			/>

			<DialogStyled showButtonClose onClose={() => setDialogVideo(false)} open={dialogVideo}>
				<VideoArtDirection
					sources={{
						xs: video?.mobile,
						md: video?.desktop,
					}}
					{...videoConfig}
				/>
			</DialogStyled>
		</>
	)
}

export default DialogVideoBanner
